// src/pages/LoginPage.jsx
import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Paper,
  Container,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../services/api";
import { useTheme } from "@mui/material";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await loginUser(username, password);
      navigate("/home");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        alert(`Login failed: ${error.response.data.detail}`);
      } else {
        alert(`Login failed: ${error.message}`);
      }
    }
  };
  
  return (
    <Container
      maxWidth="sm"
      sx={{
        backgroundColor: theme.palette.background.default,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "16px",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: isMobile ? "16px" : "24px",
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Typography variant="h4" gutterBottom align="center">
          Login
        </Typography>
        <form onSubmit={handleLogin}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            InputProps={{
              style: {
                color: theme.palette.text.primary, // Text color
                backgroundColor: theme.palette.background.paper, // Background color
              },
            }}
            InputLabelProps={{
              style: {
                color: theme.palette.text.secondary, // Label color
              },
            }}
            sx={{
              "& .MuiInputBase-input": {
                // Regular styles for the input
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
              },
              "& .MuiInputBase-input::placeholder": {
                color: theme.palette.text.secondary,
              },
              "& .MuiInputBase-input:-webkit-autofill": {
                // Override autofill styles
                WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
                WebkitTextFillColor: theme.palette.text.primary,
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: theme.palette.text.primary, // Label color when focused
              },
            }}
          />

          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              style: {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
              },
            }}
            InputLabelProps={{
              style: {
                color: theme.palette.text.secondary,
              },
            }}
            sx={{
              "& .MuiInputBase-input": {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.background.paper,
              },
              "& .MuiInputBase-input::placeholder": {
                color: theme.palette.text.secondary,
              },
              "& .MuiInputBase-input:-webkit-autofill": {
                WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
                WebkitTextFillColor: theme.palette.text.primary,
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: theme.palette.text.primary,
              },
            }}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginTop: "16px" }}
          >
            Login
          </Button>
          <Button
            variant="text"
            color="secondary"
            fullWidth
            sx={{ marginTop: "8px" }}
            onClick={() => navigate("/register")}
          >
            Don't have an account? Register
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default LoginPage;
