// src/pages/RegisterPage.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../services/api';
import { TextField, Button, Typography, Container } from '@mui/material';
import { useTheme } from '@emotion/react';

const RegisterPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      await registerUser(username, password, email);
      navigate('/');
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        alert(`Registration failed: ${error.response.data.detail}`);
      } else {
        alert(`Registration failed: ${error.message}`);
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Register
      </Typography>
      <form onSubmit={handleRegister}>
        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        InputProps={{
          style: {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
          },
        }}
        InputLabelProps={{
          style: {
            color: theme.palette.text.secondary,
          },
        }}
        sx={{
          '& .MuiInputBase-input': {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.background.paper,
          },
          '& .MuiInputBase-input::placeholder': {
            color: theme.palette.text.secondary,
          },
          '& .MuiInputBase-input:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
            WebkitTextFillColor: theme.palette.text.primary,
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: theme.palette.text.primary,
          },
        }}
      />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            style: {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.paper,
            },
          }}
          InputLabelProps={{
            style: {
              color: theme.palette.text.secondary,
            },
          }}
          sx={{
            '& .MuiInputBase-input': {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.paper,
            },
            '& .MuiInputBase-input::placeholder': {
              color: theme.palette.text.secondary,
            },
            '& .MuiInputBase-input:-webkit-autofill': {
              WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
              WebkitTextFillColor: theme.palette.text.primary,
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: theme.palette.text.primary,
            },
          }}
        />
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            style: {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.paper,
            },
          }}
          InputLabelProps={{
            style: {
              color: theme.palette.text.secondary,
            },
          }}
          sx={{
            '& .MuiInputBase-input': {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.paper,
            },
            '& .MuiInputBase-input::placeholder': {
              color: theme.palette.text.secondary,
            },
            '& .MuiInputBase-input:-webkit-autofill': {
              WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
              WebkitTextFillColor: theme.palette.text.primary,
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: theme.palette.text.primary,
            },
          }}
        />
        <Button type="submit" variant="contained" color="primary" fullWidth style={{ marginTop: '16px' }}>
          Register
        </Button>
        <Button
          variant="text"
          color="secondary"
          fullWidth
          style={{ marginTop: '8px' }}
          onClick={() => navigate('/')}
        >
          Already have an account? Login
        </Button>
      </form>
    </Container>
  );
};

export default RegisterPage;
