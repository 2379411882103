// src/components/TypingIndicator.jsx
import React from 'react';
import { styled, keyframes } from '@mui/system';

const blink = keyframes`
  0% { opacity: 0.2; }
  20% { opacity: 1; }
  100% { opacity: 0.2; }
`;

const TypingDots = styled('div')`
  display: flex;
  align-items: center;

  & > span {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 2px;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.4;
    animation: ${blink} 1.4s infinite both;

    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.4s;
    }
  }
`;

const TypingIndicator = () => (
  <TypingDots>
    <span></span>
    <span></span>
    <span></span>
  </TypingDots>
);

export default TypingIndicator;
