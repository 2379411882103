// src/pages/HomePage.jsx
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { createChat, fetchChats, fetchModels } from '../services/api';
import {
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Container,
} from '@mui/material';
import { ChatContext } from '../contexts/ChatContext';

const HomePage = () => {
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const navigate = useNavigate();
  const { setChats } = useContext(ChatContext);

  useEffect(() => {
    const loadModels = async () => {
      try {
        const data = await fetchModels();
        setModels(data);
        setSelectedModel(data[0]); // Default to first model
      } catch (error) {
        alert('Failed to load models: ' + error.message);
      }
    };
    
    const loadChats = async () => {
      try {
        const fetchedChats = await fetchChats();
        const reversedChats = fetchedChats.reverse();
        setChats(reversedChats);
      } catch (error) {
        alert('Failed to load chats: ' + error.message);
      }
    };

    loadChats();
    loadModels();
  }, []);

  const handleCreateChat = async () => {
    if (!selectedModel) {
      alert('Please select a model.');
      return;
    }
    try {
      const newChat = await createChat(selectedModel);
      setChats((prevChats) => [newChat, ...prevChats]); 
      navigate(`/chat/${newChat.id}`);
    } catch (error) {
      alert('Failed to create chat: ' + error.message);
    }
  };

  return (
    <Container>
      <Typography variant="h5" gutterBottom>
        Start a New Chat
      </Typography>
      <FormControl variant="outlined" fullWidth margin="normal">
        <InputLabel>Model</InputLabel>
        <Select
          value={selectedModel}
          onChange={(e) => setSelectedModel(e.target.value)}
          label="Model"
        >
          {models.map((model) => (
            <MenuItem key={model} value={model}>
              {model}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleCreateChat}
        style={{ marginTop: '16px' }}
      >
        Start New Chat
      </Button>
    </Container>
  );
};

export default HomePage;
