// src/services/api.js
import axios from 'axios';

const API_BASE_URL = "https://leus.fun:8000";


// Axios instance
export const api = axios.create({
  baseURL: API_BASE_URL,
});

// Add Authorization Header Interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Authentication Functions
export const loginUser = async (username, password) => {
  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);

  const response = await api.post('/login', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  const token = response.data.access_token;
  localStorage.setItem('token', token);

  return response.data;
};

export const registerUser = async (username, password, email) => {
  const requestBody = {
    username: username,
    email: email,
    password: password
  };

  const response = await api.post('/register', requestBody, {
    headers: { 'Content-Type': 'application/json' },
  });

  return response.data;
};
export const logoutUser = () => {
  localStorage.removeItem('token');
};

// Chat Functions
export const fetchChats = async () => {
  const response = await api.get('/chats');
  return response.data;
};

export const createChat = async (modelUsed) => {
  const response = await api.post('/chats', { model_used: modelUsed });
  return response.data;
};

// Message Functions
export const fetchMessages = async (chatId) => {
  const response = await api.get(`/chats/${chatId}/messages`);
  return response.data;
};

export const sendMessage = async (chatId, formData) => {
  const response = await fetch(`${API_BASE_URL}/chats/${chatId}/messages/`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: formData,
  });
  if (!response.ok) {
    throw new Error(`Error: ${response.status} ${response.statusText}`);
  }
  return await response.json();
};


// Models Function
export const fetchModels = async () => {
  const response = await api.get('/models/');
  return response.data.models;
};


// Delete a single chat
export const deleteChat = async (chatId) => {
  const response = await api.delete(`/chats/${chatId}/`);
  return response.data;
};

// Delete all chats
export const deleteAllChats = async () => {
  const response = await api.delete('/chats/all/');
  return response.data;
};


export const fetchChat = async (chatId) => {
  const response = await api.get(`/chats/${chatId}`);
  return response.data;
};
