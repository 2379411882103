// src/contexts/ChatContext.jsx
import React, { createContext, useState, useEffect } from 'react';
import { fetchChats } from '../services/api';

export const ChatContext = createContext();

const ChatContextProvider = ({ children }) => {
  const [chats, setChats] = useState([]);

  useEffect(() => {
    const loadChats = async () => {
      try {
        const fetchedChats = await fetchChats();
        const reversedChats = fetchedChats.reverse(); // Reverse the order of the chats
        setChats(reversedChats);
      } catch (error) {
        console.error('Failed to fetch chats:', error);
      }
    };
    loadChats();
  }, []);

  return (
    <ChatContext.Provider value={{ chats, setChats }}>
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContextProvider;
