// src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';
import darkTheme from './theme';
import ChatContextProvider from './contexts/ChatContext';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import HomePage from './pages/HomePage';
import ChatPage from './pages/ChatPage';
import Layout from './components/Layout';

const App = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline /> {/* Ensures background and typography follow the theme */}
      <ChatContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/home" element={<Layout><HomePage /></Layout>} />
            <Route path="/chat/:chatId" element={<Layout><ChatPage /></Layout>} />
          </Routes>
        </Router>
      </ChatContextProvider>
    </ThemeProvider>
  );
};

export default App;
