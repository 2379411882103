// src/components/Layout.jsx
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Button,
  Divider,
  useMediaQuery,
  Box,
} from '@mui/material';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  Delete as DeleteIcon,
  DeleteSweep as DeleteSweepIcon,
} from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import { logoutUser, deleteChat, deleteAllChats } from '../services/api';
import { ChatContext } from '../contexts/ChatContext';

const DrawerContent = styled('div')(({ theme }) => ({
  width: 250,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const Layout = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const { chats, setChats } = useContext(ChatContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = () => {
    logoutUser();
    navigate('/');
  };

  const handleDeleteChat = async (chatId) => {
    if (window.confirm('Are you sure you want to delete this chat?')) {
      try {
        await deleteChat(chatId);
        setChats(chats.filter((chat) => chat.id !== chatId));
      } catch (error) {
        alert('Failed to delete chat: ' + error.message);
      }
    }
  };

  const handleDeleteAllChats = async () => {
    if (window.confirm('Are you sure you want to delete all chats?')) {
      try {
        await deleteAllChats();
        setChats([]);
      } catch (error) {
        alert('Failed to delete all chats: ' + error.message);
      }
    }
  };

  const handleCreateNewChat = () => {
    navigate('/home');
    if (isMobile) {
      setDrawerOpen(false);
    }
  };

  const drawer = (
    <div>
      <Toolbar />
      <DrawerContent>
        {/* Centered "Start a New Chat" Button */}
        <Box sx={{ textAlign: 'center', padding: theme.spacing(2) }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateNewChat}
            fullWidth
          >
            Start a New Chat
          </Button>
        </Box>
        <Divider />
        <List>
          {chats.map((chat) => (
            <ListItem
              button
              key={chat.id}
              onClick={() => {
                navigate(`/chat/${chat.id}`);
                if (isMobile) {
                  setDrawerOpen(false);
                }
              }}
            >
              <ListItemText
                primary={`Chat ${chat.id}`}
                secondary={`Model: ${chat.model_used}`}
              />
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteChat(chat.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <ListItem button onClick={handleDeleteAllChats}>
            <ListItemText primary="Delete All Chats" />
            <IconButton edge="end" aria-label="delete-all">
              <DeleteSweepIcon />
            </IconButton>
          </ListItem>
        </List>
      </DrawerContent>
    </div>
  );

  const drawerWidth = 250;

  return (
    <div style={{ display: 'flex' }}>
      {/* AppBar */}
      <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1,   
          height: { xs: 56, sm: 64 }, // Responsive AppBar height
}}>
        <Toolbar>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, mb: 3}}
          >
            {drawerOpen ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }} sx={{ mb: 3}}>
            Chato
          </Typography>
          <Button color="inherit" onClick={handleLogout} sx={{ mb: 3}}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {/* Drawer */}
      <nav>
        <Drawer
          variant={isMobile ? 'temporary' : 'persistent'}
          open={drawerOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'block' },
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>

      {/* Main Content */}
      <main
        style={{
          flexGrow: 1,
          padding: theme.spacing(3),
          marginLeft: isMobile ? 0 : drawerOpen ? drawerWidth : 0,
          transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: `calc(100% - ${drawerOpen && !isMobile ? drawerWidth : 0}px)`,
        }}
      >
        <Toolbar />
        {children}
      </main>
    </div>
  );
};

export default Layout;
