import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Box,
  Snackbar,
  Modal,
  Backdrop,
  Fade,
  Container,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import CloseIcon from "@mui/icons-material/Close";
import {
  fetchMessages,
  sendMessage,
  fetchModels,
  fetchChat,
} from "../services/api";
import { useTheme } from "@mui/material/styles";
import ReactMarkdown from "react-markdown";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import { atelierCaveDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import java from "react-syntax-highlighter/dist/esm/languages/hljs/java";
import python from "react-syntax-highlighter/dist/esm/languages/hljs/python";
import TypingIndicator from "../components/TypingIndicator";

SyntaxHighlighter.registerLanguage("java", java);
SyntaxHighlighter.registerLanguage("python", python);

const ChatPage = () => {
  const { chatId } = useParams();
  const [messages, setMessages] = useState([]);
  const [content, setContent] = useState("");
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [isAssistantTyping, setIsAssistantTyping] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCode, setModalCode] = useState("");
  const [modalLanguage, setModalLanguage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]); // Manage attached files
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
    setCopySuccess(true);
  };

  const handleCloseSnackbar = () => {
    setCopySuccess(false);
  };

  const openCodeModal = (code, language) => {
    setModalCode(code);
    setModalLanguage(language);
    setModalOpen(true);
  };

  const closeCodeModal = () => {
    setModalOpen(false);
    setModalCode("");
    setModalLanguage("");
  };

  useEffect(() => {
    const loadChatData = async () => {
      try {
        const [chat, fetchedMessages, models] = await Promise.all([
          fetchChat(chatId),
          fetchMessages(chatId),
          fetchModels(),
        ]);
        setSelectedModel(chat.model_used);
        setMessages(fetchedMessages);
        setModels(models);
      } catch (error) {
        alert("Failed to load data: " + error.message);
      }
    };
    loadChatData();
  }, [chatId]);

  const handleSendMessage = async () => {
    if (!content.trim() && selectedFiles.length === 0) return;

    const formData = new FormData();
    formData.append("content", content);
    formData.append("model_used", selectedModel);
    selectedFiles.forEach((file) => formData.append("files", file));

    // Optimistic UI update with temporary message
    const tempAttachments = selectedFiles.map((file) =>
      URL.createObjectURL(file)
    );
    const tempMessage = {
      sender: "user",
      content,
      attachments: tempAttachments, // Use temporary URLs
      timestamp: new Date().toISOString(),
    };
    setMessages((prev) => [...prev, tempMessage]);
    setContent("");
    setSelectedFiles([]); // Clear the selected files
    setIsAssistantTyping(true);

    try {
      const assistantMessage = await sendMessage(chatId, formData);

      // Replace the temporary message and attachments with the actual ones from the backend
      setMessages((prev) => {
        const updatedMessages = [...prev];
        const lastIndex = updatedMessages.findIndex(
          (msg) => msg === tempMessage
        );
        if (lastIndex > -1) {
          // Update the temporary message
          updatedMessages[lastIndex] = {
            ...tempMessage,
            attachments:
              assistantMessage.attachments || tempMessage.attachments, // Replace with real URLs if provided
          };
        }
        return [...updatedMessages, assistantMessage];
      });
    } catch (error) {
      alert("Failed to send message: " + error.message);
      setMessages((prev) => prev.filter((msg) => msg !== tempMessage)); // Remove the tempMessage on failure
    } finally {
      setIsAssistantTyping(false);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFiles([...selectedFiles, ...Array.from(e.target.files)]);
  };

  const removeSelectedFile = (index) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        minHeight: "calc(100vh - 64px)",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      }}
    >
      <Container maxWidth="md">
        <Typography variant="h5" gutterBottom>
          Chat {chatId}
        </Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Model</InputLabel>
          <Select
            value={selectedModel}
            onChange={(e) => setSelectedModel(e.target.value)}
            label="Model"
          >
            {models.map((model) => (
              <MenuItem key={model} value={model}>
                {model}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ maxHeight: "60vh", overflowY: "auto", mb: 2 }}>
          {messages.map((msg, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent:
                  msg.sender === "user" ? "flex-end" : "flex-start",
                mb: 2,
              }}
            >
              <Paper
                sx={{
                  p: 2,
                  backgroundColor:
                    msg.sender === "user"
                      ? theme.palette.grey[600]
                      : theme.palette.background.paper,
                  color: msg.sender === "user" ? "#fff" : "inherit",
                  borderRadius: "15px",
                }}
              >
                <ReactMarkdown
                  children={msg.content}
                  components={{
                    code({ node, inline, className, children, ...props }) {
                      const match = /language-(\w+)/.exec(className || "");
                      const codeContent = String(children).replace(/\n$/, "");

                      const handleDoubleClick = () => {
                        openCodeModal(codeContent, match ? match[1] : "");
                      };

                      return !inline && match ? (
                        <Box sx={{ position: "relative", overflowX: "auto" }}>
                          <SyntaxHighlighter
                            style={atelierCaveDark}
                            language={match[1]}
                            PreTag="div"
                            {...props}
                          >
                            {codeContent}
                          </SyntaxHighlighter>
                          <Tooltip title="Copy code">
                            <IconButton
                              size="small"
                              sx={{ position: "absolute", top: 5, right: 5 }}
                              onClick={() => handleCopy(codeContent)}
                            >
                              <ContentCopyIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      );
                    },
                  }}
                />
                {msg.attachments?.map((attachment, idx) => (
                  <img
                    key={idx}
                    src={
                      attachment.startsWith("blob:")
                        ? attachment
                        : `${attachment}`
                    }
                    alt={`attachment-${idx}`}
                    style={{ maxWidth: "100%", borderRadius: 8, marginTop: 8 }}
                  />
                ))}
              </Paper>
            </Box>
          ))}
          {isAssistantTyping && <TypingIndicator />}
        </Box>
        <Box sx={{ mb: 2 }}>
          {selectedFiles.map((file, index) => (
            <Box
              key={index}
              sx={{
                display: "inline-block",
                mr: 1,
                mb: 1,
                position: "relative",
              }}
            >
              <img
                src={URL.createObjectURL(file)}
                alt={`preview-${index}`}
                style={{
                  width: 70,
                  height: 70,
                  objectFit: "cover",
                  borderRadius: 8,
                }}
              />
              <IconButton
                size="small"
                sx={{ position: "absolute", top: 0, right: 0 }}
                onClick={() => removeSelectedFile(index)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: 2,
          }}
        >
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="file-upload"
            type="file"
            multiple
            onChange={handleFileChange}
          />
          <label htmlFor="file-upload">
            <IconButton component="span">
              <PhotoCameraIcon />
            </IconButton>
          </label>
          <TextField
            value={content}
            onChange={(e) => setContent(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            fullWidth
            placeholder="Type a message..."
            multiline
            maxRows={4}
          />
          <Button variant="contained" onClick={handleSendMessage}>
            Send
          </Button>
        </Box>
        <Snackbar
          open={copySuccess}
          autoHideDuration={2000}
          onClose={handleCloseSnackbar}
          message="Code copied to clipboard"
        />
        <Modal
          open={modalOpen}
          onClose={closeCodeModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Fade in={modalOpen}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                bgcolor: "background.paper",
                p: 2,
                borderRadius: 2,
              }}
            >
              <Typography variant="h6" gutterBottom>
                Code Snippet
              </Typography>
              <SyntaxHighlighter
                style={atelierCaveDark}
                language={modalLanguage}
              >
                {modalCode}
              </SyntaxHighlighter>
              <Box sx={{ textAlign: "right", mt: 2 }}>
                <Button variant="contained" onClick={closeCodeModal}>
                  Close
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </Container>
    </Box>
  );
};

export default ChatPage;
